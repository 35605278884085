<template>
  <q-table
    flat
    bordered
    :grid="grid"
    :data="campaigns"
    row-key="id"
    :columns="columns"
    :pagination.sync="pagination"
    :visible-columns="visibleColumns"
    class="row-5 sticky-column-table"
  >
    <template v-slot:top-right>
      <!-- <q-btn
              unelevated
              color="primary"
              outline
              :icon="!grid ? 'eva-grid-outline' : 'eva-list-outline'"
              @click="grid = !grid"
              class="q-mr-md"
            /> -->
      <q-select
        v-model="visibleColumns"
        multiple
        outlined
        dense
        options-dense
        :display-value="$q.lang.table.columns"
        emit-value
        map-options
        :options="columns"
        option-value="name"
        options-cover
        style="min-width: 150px"
      >
        <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" v-on="itemEvents">
            <q-item-section>
              <q-item-label v-html="opt.label"></q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle :value="selected" @input="toggleOption(opt)" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <!-- <q-select multiple v-model="columnsSelect" :options="columns.map(column => column.field)" label="Standard" filled /> -->
    </template>

    <!-- :filter="filterCampaign" -->
    <template v-slot:body-cell-name="props">
      <q-td :props="props" style="max-width: 200px" class="ellipsis">
        {{ props.row.name }}
        <q-tooltip>
          {{ props.row.name }}
        </q-tooltip>
      </q-td>
    </template>

    <template v-slot:body-cell-campaign_description="props">
      <q-td :props="props" style="max-width: 300px" class="ellipsis">
        {{ props.row.campaign_description }}
        <q-tooltip>
          {{ props.row.campaign_description }}
        </q-tooltip>
      </q-td>
    </template>

    <template v-slot:body-cell-category="props">
      <q-td :props="props">
        {{ categories.find((category) => category.id == props.row.category_id)['name'] }}
      </q-td>
    </template>

    <template v-slot:body-cell-segmentation="props">
      <q-td :props="props">
        {{
          segmentations.find((segmentation) => segmentation.id == props.row.segmentation)
            ? segmentations.find((segmentation) => segmentation.id == props.row.segmentation)['description']
            : ''
        }}
      </q-td>
    </template>

    <template v-slot:body-cell-campaign_url="props">
      <q-td :props="props" style="max-width: 300px" class="ellipsis">
        {{ props.row.url }}
        <q-tooltip>
          {{ props.row.url }}
        </q-tooltip>
      </q-td>
    </template>
    <template v-slot:body-cell-attribution="props">
      <q-td :props="props">
        <div class="q-gutter-sm">
          <q-checkbox
            :value="props.row.last_click"
            :true-value="1"
            :false-value="0"
            label="Last Click"
            color="primary"
            disable
          />
          <q-checkbox
            :value="props.row.assisted"
            :true-value="1"
            :false-value="0"
            label="Assistido"
            color="primary"
            disable
          />
          <q-checkbox
            :value="props.row.direct"
            :true-value="1"
            :false-value="0"
            label="Direto"
            color="primary"
            disable
          />
          <q-checkbox
            :value="props.row.organic"
            :true-value="1"
            :false-value="0"
            label="Orgânico"
            color="primary"
            disable
          />
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-affiliate_commission="props">
      <q-td :props="props">
        <div style="min-width: 350px" v-for="val in props.row.cgd_info" :key="val.id" class="row">
          <div class="col-6 text-justify">CATEGORIA: {{ val.category }}</div>
          <div class="col-6 text-right">
            COMISSÃO:
            {{
              val.format == 'percentage'
                ? ((val.value / 100) * val.affiliate_comission).toFixed(2) + '%'
                : 'R$' + ((val.value / 100) * val.affiliate_comission).toFixed(2)
            }}
          </div>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-manager_email="props">
      <q-td :props="props"> {{ props.row.operational_manager_email }} </q-td>
    </template>
    <template v-slot:body-cell-trademark="props">
      <q-td :props="props" style="max-width: 200px" class="ellipsis">
        {{ props.row.trademark }}
        <q-tooltip>
          {{ props.row.trademark }}
        </q-tooltip>
      </q-td>
    </template>

    <!-- :filter="filterCampaign" -->
    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-chip v-if="props.value === 0" text-color="white" color="warning">Pendente</q-chip>
        <q-chip v-if="props.value === 1" text-color="white" color="primary">Ativado</q-chip>
        <q-chip v-if="props.value === 2" text-color="white" color="accent">Pausado</q-chip>
        <q-chip v-if="props.value === 3" text-color="white" color="negative">Desativado</q-chip>
        <q-chip v-if="props.value === 4" text-color="white" color="purple">Em implementação</q-chip>
      </q-td>
    </template>
    <template v-slot:body-cell-test="props">
      <q-td :props="props">
        <q-chip v-if="props.value == 0">Não</q-chip>
        <q-chip v-else>Sim</q-chip>
      </q-td>
    </template>
    <template v-slot:body-cell-restricted="props">
      <q-td :props="props">
        <q-toggle v-model="props.row.restricted" @input="updateRestricted(props.row)" />
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn unelevated round flat icon="edit_note" color="primary" @click="editCampaign(props.row)">
          <!-- :to="`/campaigns/edit/${props.row.id}`" -->
          <!-- @click="ActionSetDialogCampaign({ value: props.row })" -->
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Editar </q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:bottom>
      <!-- <pagination-table
              @change="getCampaigns"
              :data="reqPagination"
              :pagination="pagination"
            /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        <div class="row items-center">
          <span class="q-mr-md">Registros por página:</span>
          <q-select
            v-model="pagination.rowsPerPage"
            :options="[10, 20, 50, 100]"
            dense
            @input="updateRowsPerPage"
            style="max-width: 60px; padding-left: 8px; padding-right: 8px"
          />
          <span>Total: {{ reqPagination.total }}</span>
        </div>
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>
<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import AdvertiserService from '@/services/AdvertiserService.js';
// import PaginationTable from "../../widgets/PaginationTable.vue";
export default {
  name: 'ListCampaign',
  mixins: [CampaignMixin, AdvertiserService],

  data() {
    return {
      grid: false,
      categories: [],
      segmentations: [],
      //currentPage: 1,
      visibleColumns: null,
      // campaigns : [],
      columns: [
        {
          name: 'id',
          // required: true,
          label: 'ID',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: 'Nome',
          field: 'name',
          sortable: true
        },
        {
          name: 'email',
          align: 'left',
          label: 'Comercial',
          field: 'comercial_manager_name',
          sortable: true
        },
        {
          name: 'atendimento',
          align: 'left',
          label: 'Atendimento',
          field: 'operational_manager_name',
          sortable: true
        },
        {
          name: 'afiliados',
          align: 'left',
          label: 'Afiliados',
          field: 'network_manager_name',
          sortable: true
        },
        {
          name: 'trademark',
          align: 'left',
          label: 'Advertiser',
          field: 'trademark'
          // format: (val) => this.$moment(val).format("LL"),
        },
        {
          name: 'agency_name',
          align: 'left',
          label: 'Agência',
          field: 'agency_name'
        },
        {
          name: 'inicio',
          align: 'left',
          label: 'Início',
          field: 'activation_date',
          format: (val) => this.$moment(val).format('L')
        },
        {
          name: 'fim',
          align: 'left',
          label: 'Fim',
          field: 'expiration_date',
          format: (val) => this.$moment(val).format('L')
        },
        {
          align: 'left',
          name: 'status',
          label: 'Status',
          field: 'status'
        },
        {
          name: 'category',
          label: 'Categoria',
          align: 'center',
          field: 'category'
        },
        {
          name: 'segmentation',
          label: 'Segmentação',
          field: 'segmentation',
          align: 'center'
        },
        {
          name: 'attribution',
          label: 'Atribuição',
          align: 'center'
        },

        {
          name: 'manager_email',
          label: 'Email Gerente de Atendimento',
          align: 'center'
        },
        {
          name: 'campaign_description',
          label: 'Descrição da Campanha',
          align: 'left'
        },
        {
          name: 'campaign_url',
          align: 'left',
          label: 'URL da Campanha',
          field: 'url'
        },
        {
          name: 'affiliate_commission',
          label: 'Comissão  Afiliado',
          align: 'center'
        },
        {
          name: 'campaign_comission_types',
          label: 'Modelos',
          field: 'comission_types',
          align: 'center'
        },
        {
          name: 'test',
          align: 'center',
          label: this.$t('test_campaign'),
          field: 'is_test'
        },
        {
          name: 'restricted',
          align: 'right',
          label: 'Campanha Restrita',
          field: 'restricted'
          // format: (val) => val == 1 ? true : false ,
        },
        {
          name: 'action',
          align: 'right',
          label: 'Ações',
          field: 'id'
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getCampaigns(`page=${this.currentPage}`, `perPage=${this.pagination.rowsPerPage}`);
      }
    },
    visibleColumns() {
      this.ActionSetManageCampaignsVisibleColumns(
        this.columns.filter((column) => this.visibleColumns.find((val) => column.name == val))
      );
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  created() {
    const savedRowsPerPage = localStorage.getItem('rowsPerPage');
    if (savedRowsPerPage) {
      this.pagination.rowsPerPage = Number(savedRowsPerPage);
    } else {
      this.pagination.rowsPerPage = 10;
    }

    this.ActionSetFilterCampaign('is_test=0');
    this.getCampaigns('page=' + 1, 'perPage=' + this.pagination.rowsPerPage);

    var notDefaultColumns = [
      'attribution',
      'manager_email',
      'campaign_description',
      'campaign_url',
      'affiliate_commission',
      'campaign_comission_types',
      'category'
    ];

    this.visibleColumns = this.columns.map((column) => {
      if (!notDefaultColumns.includes(column.name)) return column.name;
    });
    this.getCategories();
    this.getSegmentations();
  },

  methods: {
    updateRowsPerPage(value) {
      this.pagination.rowsPerPage = value;

      localStorage.setItem('rowsPerPage', value);
      this.getCampaigns('page=' + 1, 'perPage=' + this.pagination.rowsPerPage);
    },

    editCampaign(props) {
      this.$router.push({
        name: 'EditCampaign',
        params: { id: props.id, tabCurrent: 'detalhamento' }
      });
    },

    async getCategories() {
      try {
        const { data, status } = await this.listCampaignsCategories();
        if (status === 200) {
          data.sort((a, b) => a.name.localeCompare(b.name));
          this.categories = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getSegmentations() {
      try {
        const { data, status } = await this.getSegmentationsList();
        if (status === 200) {
          this.segmentations = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async updateRestricted(row) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.put('/redirect', {
          app: 'ADVERTISER',
          path: '/api/edit/campaign/' + row.id,
          params: `{
              "data":{
                "restricted": ${row.restricted == true ? 1 : 0}
              }
            }`
        });
        if (status === 200) this.successNotify(data.message);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
<style lang="sass">
.sticky-column-table
  max-width: 2600px
  thead tr:last-child th:last-child
    background-color: #fff
  td:last-child
    background-color: #fff
  th:last-child,
  td:last-child
    position: sticky
    right: 0
    z-index: 1
</style>
